*{
    margin: 0;
    padding: 0;
}
 #super{
  
     padding-top: 1rem;
 display: flex;
 position: relative;
 padding-left:84%;
 
}
/* .loginbutton{
  height: 60;
 width: 100;

}
.jonson{
  padding-top:10px;
  padding-left: 0px;
  padding-right: 0px;
} */
#fab1{
 text-align: center;
 margin-top: 14%; 
 
}



#deffren{ 
    margin-top: 2%;
       text-align: center;
        font-style: oblique;
        font-size: 4rem;
        color: black;
      font-weight: 90;

} 
#kund{
    text-align: center;
    word-spacing: 3rem;
   

}    
.a{
    text-decoration:none;
    color: grey;
}