body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
input[type=number]{
    -moz-appearance: textfield;
}

::-webkit-scrollbar{
    width: 5px;
}
::-webkit-scrollbar-thumb{
    background-color: #75757658;
    border-radius: 10px;
    opacity: 0.5;
}
::-webkit-scrollbar-track{
    background-color: #eeeeee;
    opacity: 0.5;
}
body{
    overflow-x: hidden;
}





.goog-logo-link,
.goog-te-gadget span {
  display: none !important;
}

.goog-te-gadget {
  color: transparent !important;
  font-size: 0;
}
.goog-te-banner-frame {
  display: none !important;
}
#goog-gt-tt,
.goog-te-balloon-frame {
  display: none !important;
}
.goog-text-highlight {
  background: none !important;
  box-shadow: none !important;
}
.goog-te-gadget-simple {
  background-color: transparent;
  border: 2px solid #eee;
  border-radius: 5px;
  padding: 5px 10px;
  box-shadow: 0 2px 10px -7pxrgba (0, 0, 0, 0.2);
  font-size: 0px;
}
.goog-te-gadget img {
  display: none !important;
}
body > .skiptranslate {
  display: none;
}
body {
  top: 0px !important;
}
#goog-gt-tt,
.goog-te-balloon-frame {
  display: none !important;
}
.goog-text-highlight {
  background: none !important;
  box-shadow: none !important;
}

.goog-te-combo {
  cursor: pointer;
  border-radius: 17px;
  transition: all 300ms;
  border: 1px solid #fff !important;
}
#google_translate_element select * {
  cursor: pointer;
  border-radius: 17px !important;
  color: #000;
  background-color: rgb(250, 250, 250) !important;
}
#google_translate_element select {
  cursor: pointer;
  border-radius: 17px !important;
  background-color: #1976d2;
  color: white;
  font-size: inherit !important;
  text-transform: uppercase;
  font-size: 12px !important;
  font-family: inherit;
  padding: 6px 8px;
  outline: none;
  height: 30px;
  margin-top: 14px !important;
}
