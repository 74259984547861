.red-dot {
    background-color: red;
    color: white;
    border-radius: 50%;
    padding: 2px;
    font-size: 10px;
    position: absolute;
    width: 20px;
    height: 36%;
    top: 0;
    right: 11px;
  }

  .no-dot {
    display: none;
  }